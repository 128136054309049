// Generic imports
import styled from "styled-components"

// Static imports

export const OutMostVerifWrapper = styled.div`
  background: #fafafa;
`

export const Styles = styled.div`
  padding-bottom: 40px;

  .all {
    max-width: 1000px;
    margin: auto;
    padding-top: 75px;
    padding-bottom: 150px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h4 {
    padding-top: 20px;
  }

  .baris {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .kiri {
    width: 305px;
  }

  .kanan {
    width: calc(100% - 305px);
  }

  .ubah-foto {
    width: 252.4px;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 30px;
  }

  input[type="text"] {
    width: 100%;
    border: 1px solid #a0a4a8;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 9px 16px;
  }

  input[type="text"]:disabled {
    background: #e8e8e8;
  }

  .simpan-profil button {
    margin-top: 30px;
    width: 100%;
  }

  .fileContainer {
    padding: 0;
    margin: 0;
    width: 270px;
    height: 270px;
    background: transparent;
    box-shadow: none;
    border: 1px dashed #a0a4a8;
  }

  .chooseFileButton {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin: 0;
    background: white;
    color: black;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .has-profile-pic img {
    opacity: 0;
    transition: 0.2s opacity;
  }

  .chooseFileButton:hover {
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: white;
    opacity: 0.8;

    .has-profile-pic img {
      opacity: 1;
    }
  }

  .uploadIcon,
  .fileContainer p {
    display: none;
  }

  .login-register {
    margin-top: 0;
    background: #fafafa;

    .taman-input {
      padding-bottom: 6px;
    }
  }

  .verification-body {
    text-align: center;
    padding: 20px;
    padding-bottom: 0;

    .verification-step {
      color: gray;
      padding-top: 0.5rem;
      padding-bottom: 3rem;
    }

    h1 {
      padding-bottom: 2rem;
    }

    .verification-desc {
      max-width: 450px;
      margin: auto;
      padding-bottom: 1rem;
    }

    .no-resend {
      color: gray;
    }

    .resend-mail {
      color: #54b2e5;
      cursor: pointer;
    }

    .action-btn {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .skip-invisible-btn {
    text-align: center;

    span,
    a {
      color: #51b848;
      cursor: pointer;
    }

    span:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 767px) {
    .baris {
      flex-wrap: wrap;
    }

    .baris input {
      margin-top: 5px;
    }

    .kanan {
      width: 100%;
    }
  }
`
