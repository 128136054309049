// Generic imports
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

// Other components imports
import { AccountStyles } from "components/Account/index"
import Button from "components/Button"
import EditProfile from "components/EditProfile"
import Image from "components/image"
import {
  Styles,
  OutMostVerifWrapper,
} from "components/Account/Verification/style"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"
import * as sessionThunks from "rdx/slices/session/thunks"

// Static imports

// Utils imports
import { getParameterByName, handleAJAX } from "services/utils"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const VerificationComponent = props => {
  const { user } = props
  const [verifStep, setVerifStep] = useState(0)
  const [resendTimer, setResendTimer] = useState(0)

  let resendTimerInterval = null
  const resetTimer = () => {
    if (resendTimerInterval) {
      clearInterval(resendTimerInterval)
    }
    setResendTimer(60)
    resendTimerInterval = setInterval(() => {
      setResendTimer(resendTimer =>
        resendTimer > 0 ? resendTimer - 1 : resendTimer
      )
    }, 1000)
  }

  useEffect(() => {
    const step = getParameterByName("step")
    if (step === "2") {
      setVerifStep(1)
    }
    return () => {
      clearInterval(resendTimerInterval)
    }
  }, [])

  return (
    <OutMostVerifWrapper>
      <AccountStyles>
        <div className="taman-logo">
          <Image imgName="taman-siswa-logo-full.png" alt="TamanSiswa" />
        </div>
        {verifStep === 0 ? (
          <Styles>
            <div className="verification-body">
              <div className="verification-step">STEP 1/2</div>
              <h1>Verify Your Account</h1>
              <h5>Check Your Inbox!</h5>
              <div className="verification-desc">
                We sent an email to {user.email} click on the verification link
                inside and we’ll take it from there.
              </div>
              <div>
                Didn&apos;t receive an email from us?{` `}
                {resendTimer === 0 ? (
                  <span
                    className="resend-mail"
                    onClick={() => {
                      resetTimer()
                      handleAJAX(() => {
                        return sessionThunks.sendVerificationEmail(user.email)
                      })
                    }}
                  >
                    Resend mail
                  </span>
                ) : (
                  <span className="no-resend">Resend mail ({resendTimer})</span>
                )}
              </div>
              {user.email.endsWith("gmail.com") ? (
                <Button className="action-btn" href="https://gmail.com">
                  GO TO GMAIL.COM
                </Button>
              ) : (
                <br />
              )}
            </div>
            <div className="skip-invisible-btn">
              <span onClick={() => setVerifStep(1)}>SKIP</span>
            </div>
          </Styles>
        ) : (
          <Styles>
            <div className="verification-body">
              <div className="verification-step">STEP 2/2</div>
              <EditProfile />
            </div>
          </Styles>
        )}
      </AccountStyles>
    </OutMostVerifWrapper>
  )
}

export default withConnect(VerificationComponent)
