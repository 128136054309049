// Generic imports
import React from "react"

// Other components imports
import Layout from "components/layout"
import SEO from "components/seo"
import Verification from "components/Account/Verification"

// Redux imports

// Static imports

// Utils imports

const VerificationPage = () => (
  <Layout>
    <SEO title="Verification" />
    <Verification />
  </Layout>
)

export default VerificationPage
